import { Box, Button, Heading, VStack, useBoolean } from '@chakra-ui/react';

import ClaimToHivePopup from '../components/CliamToHivePopup';
import HoneyIcon from '../components/HoneyIcon';
import Panel from '../components/Panel';
import WalletAsset from '../components/WalletAsset';
import { formatEtherBalance } from '../utils/numberUtils';
import { useAccount } from 'wagmi';
import { useCallback } from 'react';
import useClaimHoneyRewardToWalletV2 from '../hooks/useClaimHoneyToWalletV2';
import useClaimingV2 from '../hooks/useClaimingV2';
import useTranslate from '../hooks/useTranslate';
import useWalletAssets from '../hooks/useWalletAssets';
import useWhitelists from '../hooks/useWhitelists';

const ClaimingContainer = () => {
  const translate = useTranslate();
  const { address } = useAccount();
  const { walletsWhitelist } = useWhitelists();
  const {
    claimableHoneyToWallet,
    readClaimedHoneyByWallet,
    claimableHoneyForFancyBearsSum,
    readClaimedHoneyPerFancyBear,
  } = useClaimingV2();

  const { readHoneyBalance } = useWalletAssets();

  const canClaimToWallet =
    claimableHoneyToWallet && claimableHoneyToWallet.gt(0);
  const canClaimToHive =
    claimableHoneyForFancyBearsSum && claimableHoneyForFancyBearsSum.gt(0);

  const [isClaimToHivePopupOpen, setIsClaimToHivePopupOpen] = useBoolean(false);

  const handleClaimSuccess = useCallback(() => {
    readClaimedHoneyByWallet();
    readClaimedHoneyPerFancyBear();
    readHoneyBalance();
  }, [
    readClaimedHoneyByWallet,
    readClaimedHoneyPerFancyBear,
    readHoneyBalance,
  ]);

  const [claimToWallet, isClaimingToWallet] = useClaimHoneyRewardToWalletV2(
    address,
    walletsWhitelist,
    handleClaimSuccess
  );

  const handleClaimToWallet = useCallback(() => {
    claimToWallet?.();
  }, [claimToWallet]);

  return canClaimToWallet || canClaimToHive ? (
    <>
      <Panel>
        <Heading px="1.5" pb="3.5" pt="1" fontSize="lg">
          {translate('claiming:title')}
        </Heading>

        <VStack spacing="2">
          {canClaimToWallet && (
            <WalletAsset
              icon={HoneyIcon}
              title={translate('claiming:claimableToWallet')}
              value={formatEtherBalance(claimableHoneyToWallet)}
              bg="dark.700"
            >
              <Box p="4">
                <Button
                  size="sm"
                  onClick={handleClaimToWallet}
                  isLoading={isClaimingToWallet}
                  isDisabled={isClaimingToWallet}
                >
                  {translate('common:claim')}
                </Button>
              </Box>
            </WalletAsset>
          )}

          {canClaimToHive && (
            <WalletAsset
              icon={HoneyIcon}
              title={translate('claiming:claimableToHive')}
              value={formatEtherBalance(claimableHoneyForFancyBearsSum)}
              bg="dark.700"
            >
              <Box p="4">
                <Button size="sm" onClick={setIsClaimToHivePopupOpen.on}>
                  {translate('common:claim')}
                </Button>
              </Box>
            </WalletAsset>
          )}
        </VStack>
      </Panel>

      {isClaimToHivePopupOpen && (
        <ClaimToHivePopup isOpen onClose={setIsClaimToHivePopupOpen.off} />
      )}
    </>
  ) : null;
};

export default ClaimingContainer;
