import axios from 'axios';
import { BigNumber } from 'ethers';
import config from '../constants/config';
import StakingReward from '../types/stakingReward';

export const fetchCurrentStakingReward = async (): Promise<StakingReward> => {
  const res = await axios.get<StakingReward>(`/staking-rewards/current`, {
    baseURL: config.urls.apiUrl,
  });

  return {
    ...res.data,
    pools: res.data.pools.map(pool => {
      return {
        ...pool,
        reward: {
          honey: BigNumber.from(pool.reward.honey),
        },
        rewardPerToken: {
          honey: BigNumber.from(pool.rewardPerToken.honey),
        },
      };
    }),
  };
};

const stakingRewardsApi = {
  fetchCurrentStakingReward,
};

export default stakingRewardsApi;
