import { Center, CenterProps, Icon, IconProps } from '@chakra-ui/react';
import { FaCheck } from 'react-icons/fa';

type SelectionIndicatorProps = CenterProps & {
  icon?: React.ReactElement<IconProps> | null;
  isSelected?: boolean;
};

const SelectionIndicator = ({
  isSelected = false,
  icon = <Icon as={FaCheck} w={4} h={4} />,
  ...rest
}: SelectionIndicatorProps) => (
  <Center
    bg="dark.700"
    color="bright.50"
    w="9"
    h="9"
    borderRadius="md"
    opacity={isSelected ? '1' : '0'}
    transition="opacity 0.15s"
    {...rest}
  >
    {icon}
  </Center>
);

export default SelectionIndicator;
