import Loading from '../components/Loading';
import useFetchBearsWhitelist from '../hooks/useFetchBearsWhitelist';
import useFetchWalletsWhitelist from '../hooks/useFetchWalletsWhitelist';
import { BigNumber } from 'ethers';
import { FunctionComponent, useMemo } from 'react';
import WhitelistsContext, {
  WhitelistsContextValue,
} from '../contexts/WhitelistsContext';

const WhitelistsProvider: FunctionComponent<{ children: JSX.Element }> = ({
  children,
}) => {
  const [bearsWhitelist, fetchBearsWhitelist] = useFetchBearsWhitelist();
  const [walletsWhitelist, fetchWalletsWhitelist] = useFetchWalletsWhitelist();

  const isLoading =
    bearsWhitelist === undefined || walletsWhitelist === undefined;

  const contextValue = useMemo<WhitelistsContextValue>(
    () => ({
      bearsWhitelist,
      amountsByWhitelistedBear: bearsWhitelist?.reduce(
        (acc, [tokenId, amount]) => ({
          ...acc,
          [tokenId]: BigNumber.from(amount),
        }),
        {}
      ),
      fetchBearsWhitelist,
      walletsWhitelist,
      amountsByWhitelistedWallet: walletsWhitelist?.reduce(
        (acc, [address, amount]) => ({
          ...acc,
          [address]: BigNumber.from(amount),
        }),
        {}
      ),
      fetchWalletsWhitelist,
    }),
    [
      bearsWhitelist,
      fetchBearsWhitelist,
      walletsWhitelist,
      fetchWalletsWhitelist,
    ]
  );

  return (
    <WhitelistsContext.Provider value={contextValue}>
      {isLoading ? <Loading /> : children}
    </WhitelistsContext.Provider>
  );
};

export default WhitelistsProvider;
