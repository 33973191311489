import { useContractRead } from 'wagmi';
import config from '../constants/config';
import StakingStatus from '../types/stakingStatus';

const useStakingStatus = (): [
  status: StakingStatus,
  readStakingStatus: () => void
] => {
  const { data, refetch } = useContractRead({
    addressOrName: config.contracts.fancyBearStakingContract.address,
    contractInterface: config.contracts.fancyBearStakingContract.interface,
    functionName: 'status',
  });

  return [data as any as StakingStatus, refetch];
};

export default useStakingStatus;
