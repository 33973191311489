import ClaimingV2Context, {
  ClaimingV2ContextValue,
} from '../contexts/ClaimingV2Context';
import { FunctionComponent, useMemo } from 'react';

import { BigNumber } from 'ethers';
import Loading from '../components/Loading';
import Whitelist from '../types/whitelist';
import { useAccount } from 'wagmi';
import useAllOwnedFancyBears from '../hooks/useAllOwnedFancyBears';
import useClaimedHoneyForBearsToHive from '../hooks/useClaimedHoneyForBearsToHive';
import useClaimedHoneyForWallet from '../hooks/useClaimedHoneyForWallet';
import useClaimingStatusV2 from '../hooks/useClaimingStatusV2';
import useWhitelists from '../hooks/useWhitelists';

type ClaimingV2ProviderProps = {
  children: JSX.Element;
};

const ClaimingV2Provider: FunctionComponent<ClaimingV2ProviderProps> = ({
  children,
}) => {
  const { address } = useAccount();
  const { amountsByWhitelistedBear, amountsByWhitelistedWallet } =
    useWhitelists();

  const [status, readStatus] = useClaimingStatusV2();

  const allOwnedFancyBears = useAllOwnedFancyBears();

  const [claimedHoneyByWallet, readClaimedHoneyByWallet] =
    useClaimedHoneyForWallet(address);

  const [claimedHoneyPerFancyBear, readClaimedHoneyPerFancyBear] =
    useClaimedHoneyForBearsToHive(allOwnedFancyBears);

  const claimableHoneyToWallet = useMemo(() => {
    if (address && amountsByWhitelistedWallet?.[address]) {
      return amountsByWhitelistedWallet[address].sub(
        claimedHoneyByWallet || BigNumber.from('0')
      );
    }

    return BigNumber.from('0');
  }, [address, claimedHoneyByWallet, amountsByWhitelistedWallet]);

  const claimableHoneyPerFancyBear: Whitelist<number> = useMemo(() => {
    if (
      allOwnedFancyBears &&
      claimedHoneyPerFancyBear &&
      amountsByWhitelistedBear
    ) {
      return allOwnedFancyBears.reduce((acc, tokenId) => {
        if (amountsByWhitelistedBear[tokenId]) {
          return {
            ...acc,
            [tokenId]: amountsByWhitelistedBear[tokenId].sub(
              claimedHoneyPerFancyBear[tokenId] || BigNumber.from('0')
            ),
          };
        }

        return acc;
      }, {});
    }

    return {};
  }, [allOwnedFancyBears, claimedHoneyPerFancyBear, amountsByWhitelistedBear]);

  const claimableHoneyForFancyBearsSum = useMemo(() => {
    if (claimableHoneyPerFancyBear) {
      return Object.values(claimableHoneyPerFancyBear).reduce(
        (acc, amount) => acc.add(amount),
        BigNumber.from('0') as BigNumber
      );
    }
    return BigNumber.from('0');
  }, [claimableHoneyPerFancyBear]);

  const isLoading =
    status === undefined ||
    claimedHoneyByWallet === undefined ||
    claimedHoneyPerFancyBear === undefined;

  const contextValue = useMemo<ClaimingV2ContextValue>(
    () => ({
      status,
      readStatus,
      claimedHoneyByWallet,
      readClaimedHoneyByWallet,
      claimedHoneyPerFancyBear,
      readClaimedHoneyPerFancyBear,

      claimableHoneyToWallet,
      claimableHoneyPerFancyBear,
      claimableHoneyForFancyBearsSum,
    }),
    [
      status,
      readStatus,
      claimedHoneyByWallet,
      readClaimedHoneyByWallet,
      claimedHoneyPerFancyBear,
      readClaimedHoneyPerFancyBear,
      claimableHoneyToWallet,
      claimableHoneyPerFancyBear,
      claimableHoneyForFancyBearsSum,
    ]
  );

  return (
    <ClaimingV2Context.Provider value={contextValue}>
      {isLoading ? <Loading /> : children}
    </ClaimingV2Context.Provider>
  );
};

export default ClaimingV2Provider;
