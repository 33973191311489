import { useMemo } from 'react';
import StakingReward from '../types/stakingReward';
import usePeriodDetails from './usePeriodDetails';

const usePoolRewardDetails = (
  poolId?: string,
  stakingReward?: StakingReward
) => {
  const pool = useMemo(() => {
    const pool = stakingReward?.pools.find(pool => pool.poolId === poolId);
    return pool;
  }, [poolId, stakingReward]);

  const periodDetails = usePeriodDetails(stakingReward);

  return useMemo(() => {
    if (!pool || !periodDetails) {
      return;
    }

    const now = new Date();

    const secsTillNow = Math.round(
      (now.getTime() - periodDetails.beginningOfCurrentPeriod.getTime()) / 1000
    );
    const secsToNextPeriod = Math.round(
      (periodDetails.endOfCurrentPeriod.getTime() - now.getTime()) / 1000
    );

    const perToken = pool?.rewardPerToken.honey;
    const perTokenPerSec = perToken.div(periodDetails.totalSecsInPeriod);

    return {
      collectedTillNow: perTokenPerSec.mul(secsTillNow),
      remainingToNextPeriod: perTokenPerSec.mul(secsToNextPeriod),
      perToken,
      perTokenPerSec,
    };
  }, [pool, periodDetails]);
};

export default usePoolRewardDetails;
