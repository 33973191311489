import HiWIllustration from '../components/HiWIllustration';
import SimplePopup from '../components/SimplePopup';
import useTranslate from '../hooks/useTranslate';
import { FaInfoCircle } from 'react-icons/fa';
import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Text,
  useBoolean,
  VStack,
} from '@chakra-ui/react';

const HowItWorksContainer = () => {
  const translate = useTranslate();
  const [isHiWPopupOpen, setIsHiWPopupOpen] = useBoolean();

  return (
    <>
      <Box p="4" bg="primary.500" w="full" borderRadius="lg" color="dark.900">
        <HStack justify="space-between">
          <VStack align="start">
            <Heading size="md" maxW="18rem">
              {translate('howItWorks:title')}
            </Heading>

            <Button
              size="xs"
              colorScheme="dark"
              bg="dark.900"
              leftIcon={
                <Icon as={FaInfoCircle} color="primary.500" w="4" h="4" />
              }
              mt="2"
              onClick={setIsHiWPopupOpen.on}
            >
              {translate('howItWorks:learnMore')}
            </Button>
          </VStack>

          <HiWIllustration width="50%" />
        </HStack>
      </Box>

      {isHiWPopupOpen && (
        <SimplePopup
          isOpen
          title={translate('howItWorks:popupTitle')}
          onClose={setIsHiWPopupOpen.off}
        >
          <Text fontSize="sm" opacity="0.6" mt="6">
            <ol style={{ paddingLeft: '1rem' }}>
              <li style={{ marginBottom: '0.75rem' }}>
                <p>
                  Only Fancy Bears with level 2+ are able to participate in the
                  staking system.
                </p>
              </li>
              <li style={{ marginBottom: '0.75rem' }}>
                <p>
                  There are 5 differents pools with separate token allocation:
                </p>
                <ul style={{ paddingLeft: '1rem' }}>
                  <li>for level 0–1</li>
                  <li>for level 2–4</li>
                  <li>for level 5–9</li>
                  <li>for level 10–14</li>
                  <li>and for level 15+</li>
                </ul>
                <p>
                  The token allocation is set for the pool and is divided
                  between all the Bears staked.
                  <br />
                  Staking in higher pools also gives additional benefits (rare
                  traits, access, priorities, WLs).
                </p>
              </li>
              <li style={{ marginBottom: '0.75rem' }}>
                <p>Pools are calculated by an algorithm on a monthly basis.</p>
              </li>
              <li style={{ marginBottom: '0.75rem' }}>
                <p>
                  When you hibernate a Bear, there is a 14 days awakening period
                  before you are able to remove the Bear from the cave
                  (unstake).
                </p>
              </li>
              <li style={{ marginBottom: '0.75rem' }}>
                <p>Pools are calculated by an algorithm on a monthly basis.</p>
              </li>
              <li style={{ marginBottom: '0.75rem' }}>
                <p>
                  Pools are calculated once per month, you have to cave your
                  Bear before the cut off date if you want it to take part in
                  the monthly allocation of tokens.
                </p>
              </li>
              <li style={{ marginBottom: '0.75rem' }}>
                <p>
                  Tokens are distributed once per month. The number of tokens
                  you get is the minimum number of tokens you get at the end of
                  the month. You don’t need to claim once a month, you can claim
                  once a quarter or even all tokens at once before the event.
                </p>
              </li>
              <li style={{ marginBottom: '0.75rem' }}>
                <p>
                  If you unstake your Bear during the staking window, you lose
                  the Honey he’s eligible to.
                </p>
              </li>
              <li style={{ marginBottom: '0.75rem' }}>
                <p>There are two types of pools as well:</p>
                <ul style={{ paddingLeft: '1rem' }}>
                  <li>claimable to your wallet</li>
                  <li>
                    locked inside the Bear forever (spendable in the Trait Swap
                    apps)
                  </li>
                </ul>
              </li>
            </ol>
          </Text>
        </SimplePopup>
      )}
    </>
  );
};

export default HowItWorksContainer;
