import { StandardMerkleTree } from '@openzeppelin/merkle-tree';
import { useEffect, useMemo } from 'react';
import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';
import config from '../constants/config';
import useSnackbarRpcError from './useSnackbarRpcError';
import useSnackbarRpcSuccess from './useSnackbarRpcSuccess';

export const getContractCallArgs = (
  tokenIds: number[],
  bearsWhitelist: [number, string][]
) => {
  const tree = StandardMerkleTree.of(bearsWhitelist!, ['uint256', 'uint256']);

  const bearsLeafs: [number, string][] = bearsWhitelist!.filter(([tokenId]) =>
    tokenIds.includes(tokenId)
  );
  const proofs = bearsLeafs.map(leaf => tree.getProof(leaf));
  const totalAmounts = bearsLeafs.map(([_, amount]) => amount);
  const tokens = bearsLeafs.map(([tokenId]) => tokenId);

  return [proofs, tokens, totalAmounts];
};

const useClaimHoneyRewardToHiveV2 = (
  tokenIds: number[],
  bearsWhitelist?: [number, string][],
  onSuccess?: () => void
): [
  claim: ReturnType<typeof useContractWrite>['write'],
  isLoading: boolean
] => {
  const args = useMemo(() => {
    if (tokenIds.length && bearsWhitelist?.length) {
      return getContractCallArgs(tokenIds, bearsWhitelist);
    }
  }, [tokenIds, bearsWhitelist]);

  const { config: writeConfig } = usePrepareContractWrite({
    addressOrName: config.contracts.fancyBearStakingRewardContract.address,
    contractInterface:
      config.contracts.fancyBearStakingRewardContract.interface,
    functionName: 'claimHoneyRewardToHive',
    args,
  });

  const {
    write,
    data: writeData,
    error: writeError,
    isLoading,
  } = useContractWrite(writeConfig);

  const {
    data: waitData,
    error: waitError,
    isLoading: isWaitLoading,
  } = useWaitForTransaction({
    hash: writeData?.hash,
    confirmations: 2,
  });

  useSnackbarRpcError(writeError);
  useSnackbarRpcError(waitError);
  useSnackbarRpcSuccess(waitData);

  useEffect(() => {
    if (!isLoading && waitData && onSuccess) {
      onSuccess();
    }
  }, [isLoading, waitData, onSuccess]);

  return [write, isLoading || isWaitLoading];
};

export default useClaimHoneyRewardToHiveV2;
