import { Box, Button, ModalProps, VStack } from '@chakra-ui/react';
import { useCallback, useMemo, useState } from 'react';
import useClaimHoneyRewardToHiveV2, {
  getContractCallArgs,
} from '../hooks/useClaimHoneyRewardToHiveV2';

import FancyBearTile from './FancyBearTile';
import SimplePopup from '../components/SimplePopup';
import useAllOwnedFancyBears from '../hooks/useAllOwnedFancyBears';
import useClaimingV2 from '../hooks/useClaimingV2';
import useTranslate from '../hooks/useTranslate';
import useWhitelists from '../hooks/useWhitelists';

type ClaimToHivePopupProps = Omit<ModalProps, 'children'>;

const ClaimToHivePopup = ({ onClose, ...rest }: ClaimToHivePopupProps) => {
  const translate = useTranslate();
  const { bearsWhitelist } = useWhitelists();
  const { claimableHoneyPerFancyBear, readClaimedHoneyPerFancyBear } =
    useClaimingV2();

  const allOwnedFancyBears = useAllOwnedFancyBears();

  const handleClaimSuccess = useCallback(() => {
    readClaimedHoneyPerFancyBear();
    onClose();
  }, [readClaimedHoneyPerFancyBear, onClose]);

  const bearsWithClaimableHoney = useMemo(
    () =>
      allOwnedFancyBears?.filter(
        tokenId =>
          claimableHoneyPerFancyBear?.[tokenId] &&
          claimableHoneyPerFancyBear[tokenId].gt('0')
      ) || [],
    [allOwnedFancyBears, claimableHoneyPerFancyBear]
  );

  const [selectedBears, setSelectedBears] = useState<number[]>(
    bearsWithClaimableHoney
  );

  const [claimToHive, isClaiming] = useClaimHoneyRewardToHiveV2(
    selectedBears,
    bearsWhitelist,
    handleClaimSuccess
  );

  const hasMultipleBears =
    bearsWithClaimableHoney && bearsWithClaimableHoney.length > 1;

  const handleClaimAll = useCallback(() => {
    setSelectedBears(bearsWithClaimableHoney);

    if (claimToHive && bearsWhitelist) {
      claimToHive({
        recklesslySetUnpreparedArgs: getContractCallArgs(
          bearsWithClaimableHoney,
          bearsWhitelist
        ),
      });
    }
  }, [bearsWithClaimableHoney, bearsWhitelist, claimToHive]);

  const handleClaim = useCallback(
    (tokenId: number) => {
      setSelectedBears([tokenId]);

      if (claimToHive && bearsWhitelist) {
        claimToHive({
          recklesslySetUnpreparedArgs: getContractCallArgs(
            [tokenId],
            bearsWhitelist
          ),
        });
      }
    },
    [claimToHive, bearsWhitelist]
  );

  const renderTile = useCallback(
    (tokenId: number) => {
      const claimableHoney = claimableHoneyPerFancyBear?.[tokenId];

      return claimableHoney ? (
        <FancyBearTile
          key={tokenId}
          tokenId={tokenId}
          w="full"
          claimableHoney={claimableHoney}
        >
          <Box p="2">
            <Button
              size="sm"
              onClick={() => handleClaim(tokenId)}
              isDisabled={isClaiming}
              isLoading={isClaiming && selectedBears.includes(tokenId)}
              colorScheme={isClaiming ? 'dark' : 'primary'}
            >
              {translate('common:claim')}
            </Button>
          </Box>
        </FancyBearTile>
      ) : null;
    },
    [
      handleClaim,
      claimableHoneyPerFancyBear,
      translate,
      selectedBears,
      isClaiming,
    ]
  );

  return (
    <SimplePopup
      size="xl"
      title={translate('claimToHive:title')}
      description={translate('claimToHive:description')}
      onClose={onClose}
      isClosable={!isClaiming}
      {...rest}
    >
      <VStack spacing="2" pt="6">
        {bearsWithClaimableHoney?.map(renderTile)}
      </VStack>

      {hasMultipleBears && (
        <Button
          mt="6"
          w="full"
          colorScheme="dark"
          onClick={handleClaimAll}
          isDisabled={isClaiming}
          isLoading={isClaiming}
        >
          {translate('claimToHive:claimAll')}
        </Button>
      )}
    </SimplePopup>
  );
};

export default ClaimToHivePopup;
