import { BigNumber } from 'ethers';
import { useContractRead } from 'wagmi';
import config from '../constants/config';

const useMinimumHoneyConsumption = (): [
  minimumHoneyConsumption: BigNumber,
  readMinimumHoneyConsumption: () => void
] => {
  const { data, refetch } = useContractRead({
    addressOrName: config.contracts.fancyBearStakingContract.address,
    contractInterface: config.contracts.fancyBearStakingContract.interface,
    functionName: 'minimumHoneyConsumption',
  });

  return [data as any as BigNumber, refetch];
};

export default useMinimumHoneyConsumption;
