import StakedToken from '../types/stakedToken';
import axios from 'axios';
import config from '../constants/config';

export const fetchStakingTokens = async (
  walletAddress: string
): Promise<StakedToken[]> => {
  const res = await axios.get<(StakedToken & { nftId: string })[]>(
    `/wallets/${walletAddress}/staking-tokens?status=staked`,
    {
      baseURL: config.urls.apiUrl,
    }
  );

  return res.data.map(stakedToken => ({
    ...stakedToken,
    nftId: parseInt(stakedToken.nftId),
  }));
};

const walletsApi = {
  fetchStakingTokens,
};

export default walletsApi;
