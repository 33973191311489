import { Box, Button, ModalProps, Text } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import useStakeFancyBear from '../hooks/useStakeFancyBear';
import useTranslate from '../hooks/useTranslate';
import FancyBearImage from './FancyBearImage';
import RefreshCountdown from './RefreshCountdown';
import SimplePopup from './SimplePopup';

type StakePopupProps = Omit<ModalProps, 'children'> & {
  tokenId: number;
};

const StakePopup = ({ tokenId, ...rest }: StakePopupProps) => {
  const translate = useTranslate();
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSuccess = useCallback(() => {
    setIsSuccess(true);
  }, []);

  const [stake, isLoading] = useStakeFancyBear(tokenId, handleSuccess);

  const handleStake = useCallback(() => {
    stake();
  }, [stake]);

  return (
    <SimplePopup
      size="md"
      title={translate('stakePopup:title')}
      description={translate('stakePopup:description')}
      isClosable={!isLoading}
      {...rest}
    >
      <FancyBearImage tokenId={tokenId} mt="4" />

      {isSuccess ? (
        <Box mt="6" borderRadius="lg" bg="dark.800">
          <Text
            fontSize="sm"
            opacity="0.8"
            textAlign="center"
            p="3"
            maxW="20em"
            m="0 auto"
          >
            {translate('common:waitMessage')}
          </Text>

          <RefreshCountdown seconds={10} borderTopRadius="0" />
        </Box>
      ) : (
        <Button w="full" mt="6" onClick={handleStake} isLoading={isLoading}>
          {translate('stakePopup:confirm', { tokenId })}
        </Button>
      )}
    </SimplePopup>
  );
};

export default StakePopup;
