import Loading from '../components/Loading';
import useFancyBearsInWallet from '../hooks/useFancyBearsInWallet';
import useHoneyBalance from '../hooks/useHoneyBalance';
import { FunctionComponent, useMemo } from 'react';
import { useAccount, useBalance } from 'wagmi';
import WalletAssetsContext, {
  WalletAssetsContextValue,
} from '../contexts/WalletAssetsContext';

type WalletAssetsProviderProps = {
  children: JSX.Element;
};

const WalletAssetsProvider: FunctionComponent<WalletAssetsProviderProps> = ({
  children,
}) => {
  const { address } = useAccount();

  const { data: balance, refetch: readBalance } = useBalance({
    addressOrName: address,
  });

  const [fancyBears, readFancyBears] = useFancyBearsInWallet(address);

  const [honeyBalance, readHoneyBalance] = useHoneyBalance(address);

  const isLoading =
    fancyBears === undefined ||
    honeyBalance === undefined ||
    balance === undefined;

  const contextValue = useMemo<WalletAssetsContextValue>(
    () => ({
      balance: balance?.value,
      readBalance,
      fancyBears,
      readFancyBears,
      honeyBalance,
      readHoneyBalance,
    }),
    [
      balance,
      readBalance,
      fancyBears,
      readFancyBears,
      honeyBalance,
      readHoneyBalance,
    ]
  );

  return (
    <WalletAssetsContext.Provider value={contextValue}>
      {isLoading ? <Loading /> : children}
    </WalletAssetsContext.Provider>
  );
};

export default WalletAssetsProvider;
