import React from 'react';

const MetaMaskIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M26.8794 2.00003L16.252 9.89314L18.2172 5.23631L26.8794 2.00003Z"
      fill="#E2761B"
    />
    <path
      d="M2.34414 2.00003L12.8861 9.96791L11.017 5.23631L2.34414 2.00003ZM23.0542 20.2962L20.2238 24.6326L26.2798 26.2989L28.0208 20.3924L23.0542 20.2962ZM1.22266 20.3924L2.95295 26.2989L9.00896 24.6326L6.17855 20.2962L1.22266 20.3924Z"
      fill="#E4761B"
    />
    <path
      d="M8.66803 12.9692L6.98047 15.5219L12.9938 15.7889L12.7801 9.32705L8.66803 12.9692ZM20.5558 12.9692L16.3903 9.25229L16.2514 15.7889L22.254 15.5219L20.5558 12.9692ZM9.00982 24.6326L12.6199 22.8703L9.50114 20.4351L9.00982 24.6326ZM16.6039 22.8703L20.2247 24.6326L19.7227 20.4351L16.6039 22.8703Z"
      fill="#E4761B"
    />
    <path
      d="M20.2246 24.6326L16.6038 22.8703L16.8922 25.2308L16.8602 26.2241L20.2246 24.6326ZM9.00977 24.6326L12.3742 26.2241L12.3529 25.2308L12.6199 22.8703L9.00977 24.6326Z"
      fill="#D7C1B3"
    />
    <path
      d="M12.428 18.8757L9.41602 17.9892L11.5415 17.0172L12.428 18.8757ZM16.7964 18.8757L17.683 17.0172L19.8191 17.9892L16.7964 18.8757Z"
      fill="#233447"
    />
    <path
      d="M9.00912 24.6326L9.5218 20.2962L6.17871 20.3924L9.00912 24.6326ZM19.7113 20.2962L20.224 24.6326L23.0544 20.3924L19.7113 20.2962ZM22.2533 15.5219L16.2507 15.7889L16.8061 18.8757L17.6926 17.0172L19.8288 17.9892L22.2533 15.5219ZM9.41499 17.9892L11.5512 17.0172L12.427 18.8757L12.9931 15.7889L6.97977 15.5219L9.41499 17.9892Z"
      fill="#CD6116"
    />
    <path
      d="M6.98047 15.5219L9.50114 20.4351L9.41569 17.9892L6.98047 15.5219ZM19.8295 17.9892L19.7227 20.4351L22.254 15.5219L19.8295 17.9892ZM12.9938 15.7889L12.4277 18.8757L13.1326 22.5178L13.2928 17.7222L12.9938 15.7889ZM16.2514 15.7889L15.963 17.7115L16.0912 22.5178L16.8068 18.8757L16.2514 15.7889Z"
      fill="#E4751F"
    />
    <path
      d="M16.8071 18.8757L16.0915 22.5178L16.6042 22.8703L19.723 20.4351L19.8298 17.9892L16.8071 18.8757ZM9.41602 17.9892L9.50146 20.4351L12.6203 22.8703L13.1329 22.5178L12.428 18.8757L9.41602 17.9892Z"
      fill="#F6851B"
    />
    <path
      d="M16.8602 26.2241L16.8922 25.2308L16.6252 24.9958H12.5985L12.3529 25.2308L12.3742 26.2241L9.00977 24.6326L10.1847 25.5939L12.5665 27.2494H16.6572L19.0497 25.5939L20.2246 24.6326L16.8602 26.2241Z"
      fill="#C0AD9E"
    />
    <path
      d="M16.6045 22.8703L16.0918 22.5178H13.1332L12.6205 22.8703L12.3535 25.2307L12.5992 24.9958H16.6258L16.8929 25.2307L16.6045 22.8703Z"
      fill="#161616"
    />
    <path
      d="M27.3272 10.4058L28.2351 6.04804L26.8786 2.00002L16.6037 9.62611L20.5556 12.9692L26.1416 14.6034L27.3806 13.1615L26.8466 12.7769L27.701 11.9972L27.0388 11.4846L27.8933 10.833L27.3272 10.4058ZM0.999023 6.04804L1.90689 10.4058L1.33013 10.833L2.18459 11.4846L1.53306 11.9972L2.38753 12.7769L1.85349 13.1615L3.08178 14.6034L8.66784 12.9692L12.6197 9.62611L2.3448 2.00002L0.999023 6.04804Z"
      fill="#763D16"
    />
    <path
      d="M26.141 14.6033L20.5549 12.9692L22.2532 15.5219L19.7218 20.4351L23.0542 20.3923H28.0208L26.141 14.6033ZM8.66717 12.9692L3.08112 14.6033L1.22266 20.3923H6.17855L9.50028 20.4351L6.97961 15.5219L8.66717 12.9692ZM16.2505 15.7889L16.603 9.62609L18.2265 5.23628H11.017L12.6191 9.62609L12.9929 15.7889L13.1211 17.7328L13.1317 22.5178H16.0903L16.1117 17.7328L16.2505 15.7889Z"
      fill="#F6851B"
    />
  </svg>
);

export default MetaMaskIcon;
