import { Button, Icon, useBreakpointValue } from '@chakra-ui/react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import EmptyFancyBearCard, {
  EmptyFancyBearCardProps,
} from './EmptyFancyBearCard';

type EmptyFancyBearCardWithLinkProps = EmptyFancyBearCardProps & {
  label: string;
  externalUrl: string;
};

const EmptyFancyBearCardWithLink = ({
  label,
  externalUrl,
  ...rest
}: EmptyFancyBearCardWithLinkProps) => {
  const buttonSize = useBreakpointValue({
    base: 'xs',
    sm: 'sm',
  });

  return (
    <EmptyFancyBearCard position="relative" {...rest}>
      <Button
        mt="2"
        colorScheme="dark"
        as="a"
        w="full"
        href={externalUrl}
        target="_blank"
        rel="noreferrer"
        size={buttonSize}
        cursor="pointer"
        rightIcon={<Icon as={FaExternalLinkAlt} />}
      >
        {label}
      </Button>
    </EmptyFancyBearCard>
  );
};

export default EmptyFancyBearCardWithLink;
