import React from 'react';

const WalletConnectIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.14393 9.51631C11.0343 4.8279 18.9657 4.8279 23.8561 9.51631L24.4442 10.0768C24.6918 10.3116 24.6918 10.6903 24.4442 10.9251L22.4323 12.8565C22.3085 12.9701 22.115 12.9701 21.9912 12.8565L21.1787 12.0764C17.7663 8.80433 12.2337 8.80433 8.82125 12.0764L7.9546 12.9095C7.8308 13.0231 7.63735 13.0231 7.51354 12.9095L5.49394 10.9857C5.24632 10.7509 5.24632 10.3722 5.49394 10.1374L6.14393 9.51631ZM28.0191 13.5079L29.8143 15.2272C30.0619 15.462 30.0619 15.8407 29.8143 16.0755L21.7281 23.8239C21.4805 24.0587 21.0859 24.0587 20.846 23.8239L15.1122 18.3251C15.0503 18.2645 14.9497 18.2645 14.8878 18.3251L9.15399 23.8239C8.90637 24.0587 8.51174 24.0587 8.27186 23.8239L0.185711 16.0755C-0.0619035 15.8407 -0.0619035 15.462 0.185711 15.2272L1.98091 13.5079C2.22853 13.2731 2.62316 13.2731 2.86304 13.5079L8.59685 19.0067C8.65876 19.0673 8.75935 19.0673 8.82125 19.0067L14.5551 13.5079C14.8027 13.2731 15.1973 13.2731 15.4372 13.5079L21.171 19.0067C21.2329 19.0673 21.3335 19.0673 21.3954 19.0067L27.1292 13.5079C27.3768 13.2731 27.7715 13.2731 28.0191 13.5079Z"
      fill="#3B99FC"
    />
  </svg>
);

export default WalletConnectIcon;
