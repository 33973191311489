import ClaimingV2Provider from '../providers/ClaimingV2Provider';
import LevelsProvider from '../providers/LevelsProvider';
import StakingProvider from '../providers/StakingProvider';
import WalletAssetsProvider from '../providers/WalletAssetsProvider';
import WhitelistsProvider from '../providers/WhitelistsProvider';

type ComposedProvidersProps = {
  children: JSX.Element;
};

const ComposedProviders = ({ children }: ComposedProvidersProps) => {
  return (
    <WalletAssetsProvider>
      <StakingProvider>
        <WhitelistsProvider>
          <ClaimingV2Provider>
            <LevelsProvider>{children}</LevelsProvider>
          </ClaimingV2Provider>
        </WhitelistsProvider>
      </StakingProvider>
    </WalletAssetsProvider>
  );
};

export default ComposedProviders;
