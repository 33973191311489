import config from '../constants/config';
import LevelData from '../types/levelData';
import { BigNumber } from 'ethers';
import { getNftLevel } from '../utils/levelsUtils';
import { useContractReads } from 'wagmi';
import { useMemo } from 'react';

const levelsContract = {
  addressOrName: config.contracts.levelsContract.address,
  contractInterface: config.contracts.levelsContract.interface,
};

const useLevelDataByTokenIds = (
  tokenIds?: number[]
): [
  levelsDataByTokenIds: Record<number, LevelData> | undefined,
  readLevelDataByTokenIds: () => void
] => {
  const contracts = useMemo(
    () =>
      tokenIds?.map(tokenId => ({
        ...levelsContract,
        functionName: 'getConsumedToken',
        args: [
          config.contracts.fancyBearsContract.address,
          tokenId,
          config.contracts.honeyTokenContract.address,
        ],
      })) || [],
    [tokenIds]
  );

  const { data, refetch } = useContractReads({
    contracts,
    allowFailure: false,
  });

  const result: Record<number, LevelData> | undefined = useMemo(() => {
    if (!tokenIds || !data) {
      return;
    }

    return (data as any as BigNumber[])?.reduce(
      (aggr, current, index) => ({
        ...aggr,
        [tokenIds[index]]: {
          consumedHoney: current,
          level: getNftLevel(current),
        },
      }),
      {}
    );
  }, [data, tokenIds]);

  return [tokenIds?.length ? result : {}, refetch];
};

export default useLevelDataByTokenIds;
