import React from 'react';

const FancyBearIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#fancyBearIconClipPath)">
      <path
        d="M26.472 9.52573C26.2119 8.93392 26.3791 8.23115 26.8993 7.84278C30.1508 5.38307 28.9245 3.33023 27.9212 2.12811C27.4195 1.51781 25.2085 -0.57202 22.2357 3.20077C21.8641 3.68161 21.251 3.92204 20.6564 3.92204H9.34125C8.72811 3.92204 8.11497 3.66312 7.72479 3.18227C4.73342 -0.516538 2.54098 1.49931 2.05791 2.10962C1.09175 3.34872 -0.153109 5.40156 3.09838 7.84278C3.61862 8.23115 3.78584 8.93392 3.5443 9.52573C3.11696 10.4874 2.50382 11.9669 2.00217 13.317C1.46335 15.0185 1.37045 16.8309 1.72347 18.5693C2.09507 20.3263 2.894 21.9537 4.08312 23.3408C6.53568 26.5957 13.0201 28.5191 14.5622 28.9445C14.7852 29 15.0081 29.0185 15.2311 28.963C16.7732 28.6116 23.0718 26.9656 25.896 23.3408C27.1037 21.9722 27.9398 20.3448 28.2928 18.5878C28.6458 16.8309 28.5343 15.0185 27.9584 13.317C27.4939 11.9115 26.8807 10.4689 26.472 9.52573ZM21.6226 18.0885C21.3996 19.0502 20.9723 19.9749 20.3592 20.7701C19.746 21.5654 18.9471 22.2311 18.0367 22.712C17.1263 23.1928 16.1229 23.4887 15.0825 23.5812C11.5337 23.5812 6.90727 19.4385 8.57947 15.1664C10.4746 10.3395 11.4965 6.75163 15.0825 6.75163C18.6684 6.75163 20.3963 10.4134 21.604 15.1664C21.827 16.1281 21.827 17.1268 21.6226 18.0885Z"
        fill="currentColor"
      />
      <path
        d="M18.4465 14.4452C18.1678 15.1294 17.7033 15.7212 17.1088 16.1836C16.4771 16.6644 15.7153 16.9788 14.9349 17.0713C14.1917 16.9234 13.4857 16.609 12.8911 16.1466C12.2966 15.7028 11.8321 15.1109 11.5162 14.4452C10.8288 13.0951 10.773 11.819 14.9349 11.819C19.5056 11.819 19.0968 13.0766 18.4465 14.4452Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="fancyBearIconClipPath">
        <rect width="28" height="28" fill="white" transform="translate(1 1)" />
      </clipPath>
    </defs>
  </svg>
);

export default FancyBearIcon;
