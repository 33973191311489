import walletsApi from '../api/walletsApi';
import StakedToken from '../types/stakedToken';
import useFetchPromise from './useFetchPromise';

const useStakedTokensByWallet = (walletAddress?: string) =>
  useFetchPromise<StakedToken[], string>(
    walletsApi.fetchStakingTokens,
    walletAddress
  );

export default useStakedTokensByWallet;
