/* eslint-disable react-hooks/exhaustive-deps */
import { TransactionReceipt } from '@ethersproject/providers';

import { useMemo } from 'react';
import useTranslate from '../hooks/useTranslate';
import useSnackbarSuccess from '../hooks/useSnackbarSuccess';

const useSnackbarRpcSuccess = (data?: TransactionReceipt) => {
  const translate = useTranslate();

  const dataWithMessage = useMemo(() => {
    if (data) {
      return { message: translate('success:default') };
    }
  }, [data]);

  return useSnackbarSuccess(dataWithMessage);
};

export default useSnackbarRpcSuccess;
