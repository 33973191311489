import { BigNumber } from 'ethers';
import { useContractRead } from 'wagmi';
import config from '../constants/config';

const useClaimedHoneyForWallet = (
  address?: string
): [amount: BigNumber | undefined, read: () => void] => {
  const { data, refetch } = useContractRead({
    addressOrName: config.contracts.fancyBearStakingRewardContract.address,
    contractInterface:
      config.contracts.fancyBearStakingRewardContract.interface,
    functionName: 'claimedHoneyForWallets',
    args: address,
  });

  return [data ? (data as any as BigNumber) : undefined, refetch];
};

export default useClaimedHoneyForWallet;
