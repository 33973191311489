import React from 'react';

const EthIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.0815 11.542C15.0286 11.5177 14.9677 11.5177 14.9147 11.542L8 14.7149L14.9981 18.8863L21.9935 14.7149L15.0815 11.542Z"
      fill="currentColor"
    />
    <path
      d="M8 14.7149L14.9981 18.8863L14.9981 3L8 14.7149Z"
      fill="currentColor"
    />
    <path
      d="M14.9981 3L14.9981 18.8863L21.9935 14.7149L14.9981 3Z"
      fill="currentColor"
    />
    <path
      d="M8.80567 16.5331C8.62031 16.4226 8.41552 16.6435 8.5397 16.82L14.9981 26V20.2243L8.80567 16.5331Z"
      fill="currentColor"
    />
    <path
      d="M14.9981 20.2243V26L21.4601 16.8199C21.5843 16.6434 21.3796 16.4225 21.1942 16.5329L14.9981 20.2243Z"
      fill="currentColor"
    />
  </svg>
);

export default EthIcon;
