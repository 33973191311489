import { BigNumber } from 'ethers';
import { parseEther } from 'ethers/lib/utils';

const jarLockedHoneyDefault = 71000;
const nftMaxLevel = 15;

export const getNftLevel = (consumedAmount: BigNumber) => {
  if (!consumedAmount) {
    return 0;
  }

  const jarSize = parseEther(String(jarLockedHoneyDefault));
  let requiredHoney = BigNumber.from('0');
  let level = 0;

  while (
    requiredHoney.lte(BigNumber.from(consumedAmount)) &&
    level < nftMaxLevel + 1
  ) {
    ++level;
    requiredHoney = requiredHoney.add(BigNumber.from(level).mul(jarSize));
  }

  return level - 1;
};
