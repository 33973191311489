import { FunctionComponent, useMemo } from 'react';
import LevelsContext, { LevelsContextValue } from '../contexts/LevelsContext';

import Loading from '../components/Loading';
import useAllOwnedFancyBears from '../hooks/useAllOwnedFancyBears';
import useLevelDataByTokenIds from '../hooks/useLevelDataByTokenIds';

type LevelsProviderProps = {
  children: JSX.Element;
};

const LevelsProvider: FunctionComponent<LevelsProviderProps> = ({
  children,
}) => {
  const fancyBears = useAllOwnedFancyBears();

  const [levelsDataByTokenIds, readLevelDataByTokenIds] =
    useLevelDataByTokenIds(fancyBears);

  const isLoading = levelsDataByTokenIds === undefined;

  const contextValue = useMemo<LevelsContextValue>(
    () => ({
      levelsDataByTokenIds,
      readLevelDataByTokenIds,
    }),
    [levelsDataByTokenIds, readLevelDataByTokenIds]
  );

  return (
    <LevelsContext.Provider value={contextValue}>
      {isLoading ? <Loading /> : children}
    </LevelsContext.Provider>
  );
};

export default LevelsProvider;
