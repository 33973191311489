import { Button, SimpleGrid, useBoolean } from '@chakra-ui/react';
import { useCallback, useState } from 'react';
import EmptyFancyBearCardWithLink from '../components/EmptyFancyBearCardWithLink';
import FancyBearCard from '../components/FancyBearCard';
import StakePopup from '../components/StakePopup';
import config from '../constants/config';
import useLevels from '../hooks/useLevels';
import useStaking from '../hooks/useStaking';
import useTranslate from '../hooks/useTranslate';
import useWalletAssets from '../hooks/useWalletAssets';
import StakingStatus from '../types/stakingStatus';
import { getNftLevel } from '../utils/levelsUtils';

const FancyBearsInWalletContainer = () => {
  const translate = useTranslate();
  const { fancyBears } = useWalletAssets();
  const { status, minimumHoneyConsumption } = useStaking();
  const { levelsDataByTokenIds } = useLevels();

  const [tokenId, setTokenId] = useState<number>();
  const [isStakePopupOpen, setIsStakePopupOpen] = useBoolean(false);

  const handleStake = useCallback(
    (tokenId: number) => {
      setTokenId(tokenId);
      setIsStakePopupOpen.on();
    },
    [setIsStakePopupOpen]
  );

  const renderCard = useCallback(
    (tokenId: number) => {
      const levelData = levelsDataByTokenIds?.[tokenId];

      const hasMinimumLevel =
        minimumHoneyConsumption && levelData
          ? levelData.consumedHoney.gte(minimumHoneyConsumption)
          : false;

      const minimumLevel = minimumHoneyConsumption
        ? getNftLevel(minimumHoneyConsumption)
        : 0;

      return (
        <FancyBearCard
          key={tokenId}
          tokenId={tokenId}
          level={levelData?.level}
          imageSize="256"
          isDisabled={!hasMinimumLevel}
        >
          {hasMinimumLevel ? (
            <Button
              w="full"
              size="sm"
              mt="2"
              onClick={() => handleStake(tokenId)}
              isDisabled={status === StakingStatus.Off}
            >
              {translate('common:stake')} #{tokenId}
            </Button>
          ) : (
            <Button w="full" size="sm" mt="2" isDisabled colorScheme="dark">
              {translate('fancyBearsInWallet:upgrade', {
                level: minimumLevel,
              })}
            </Button>
          )}
        </FancyBearCard>
      );
    },
    [
      status,
      handleStake,
      translate,
      levelsDataByTokenIds,
      minimumHoneyConsumption,
    ]
  );

  return (
    <>
      <SimpleGrid w="full" columns={2} gap="2">
        {fancyBears?.map(renderCard)}

        <EmptyFancyBearCardWithLink
          label={translate('fancyBearsInWallet:buy')}
          externalUrl={config.urls.fancyBearsCollectionUrl}
        />
      </SimpleGrid>

      {isStakePopupOpen && tokenId && (
        <StakePopup
          tokenId={tokenId}
          isOpen={isStakePopupOpen}
          onClose={setIsStakePopupOpen.off}
        />
      )}
    </>
  );
};

export default FancyBearsInWalletContainer;
