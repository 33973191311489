import { Badge, Box, Container, HStack, VStack } from '@chakra-ui/react';
import { useMemo, useState } from 'react';

import ClaimingContainer from '../containers/ClaimingContainer';
import ClaimingStatus from '../types/claimingStatus';
import FancyBearsInWalletContainer from '../containers/FancyBearsInWalletContainer';
import HowItWorksContainer from '../containers/HowItWorksContainer';
import Panel from '../components/Panel';
import StakedFancyBearsContainer from '../containers/StakedFancyBearsContainer';
import StakingPeriodContainer from '../containers/StakingPeriodContainer';
import Tab from '../components/Tab';
import useClaimingV2 from '../hooks/useClaimingV2';
import useStaking from '../hooks/useStaking';
import useTranslate from '../hooks/useTranslate';
import useWalletAssets from '../hooks/useWalletAssets';

const ConnectedPage = () => {
  const translate = useTranslate();
  const { stakedTokens, stakingReward } = useStaking();
  const { fancyBears } = useWalletAssets();
  const { status } = useClaimingV2();
  const [currentTab, setCurrentTab] = useState<'wallet' | 'staked'>('wallet');

  const hasStakedTokens = stakedTokens && stakedTokens?.length > 0;
  const hasFancyBears = fancyBears && fancyBears?.length > 0;

  const hasNoRewards = useMemo(
    () =>
      stakingReward
        ? stakingReward?.pools.some(({ reward }) => reward.honey.isZero())
        : true,
    [stakingReward]
  );

  return (
    <Container maxW="lg" pt="12" pb="16">
      <VStack spacing="3">
        <HowItWorksContainer />

        {status === ClaimingStatus.On && <ClaimingContainer />}

        <Panel p="0">
          <HStack w="full" spacing="0">
            <Tab
              w="full"
              size="lg"
              borderTopRightRadius="0"
              isActive={currentTab === 'wallet'}
              onClick={() => setCurrentTab('wallet')}
            >
              {translate('tabs:bearsInWallet')}

              {hasFancyBears && (
                <Badge
                  ml="2"
                  minW="5"
                  lineHeight="5"
                  color="bright.100"
                  bg="dark.500"
                >
                  {fancyBears.length}
                </Badge>
              )}
            </Tab>
            <Tab
              w="full"
              size="lg"
              borderTopLeftRadius="0"
              isActive={currentTab === 'staked'}
              onClick={() => setCurrentTab('staked')}
            >
              {translate('tabs:stakedBears')}

              {hasStakedTokens && (
                <Badge
                  ml="2"
                  minW="5"
                  lineHeight="5"
                  color="bright.100"
                  bg="dark.500"
                >
                  {stakedTokens.length}
                </Badge>
              )}
            </Tab>
          </HStack>

          <Box p="2">
            {currentTab === 'wallet' && <FancyBearsInWalletContainer />}
            {currentTab === 'staked' && (
              <VStack spacing="2">
                {!hasNoRewards && (
                  <Box pb="1" w="full">
                    <StakingPeriodContainer />
                  </Box>
                )}
                <StakedFancyBearsContainer />
              </VStack>
            )}
          </Box>
        </Panel>
      </VStack>
    </Container>
  );
};

export default ConnectedPage;
