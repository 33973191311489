import React from 'react';
import Whitelist from '../types/whitelist';

export type WhitelistsContextValue = {
  bearsWhitelist?: [number, string][];
  amountsByWhitelistedBear?: Whitelist<number>;
  fetchBearsWhitelist: () => Promise<void>;
  walletsWhitelist?: [string, string][];
  amountsByWhitelistedWallet?: Whitelist<string>;
  fetchWalletsWhitelist: () => Promise<void>;
};

// @ts-ignore
const WhitelistsContext = React.createContext<WhitelistsContextValue>();

WhitelistsContext.displayName = 'WhitelistsContext';

export default WhitelistsContext;
