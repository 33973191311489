import { useEffect } from 'react';
import {
  useContractWrite,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';
import config from '../constants/config';
import useSnackbarRpcError from './useSnackbarRpcError';
import useSnackbarRpcSuccess from './useSnackbarRpcSuccess';

const useUnstakeFancyBear = (
  tokenId?: number,
  onSuccess?: () => void
): [unstake: () => void, isLoading: boolean] => {
  const { config: writeConfig } = usePrepareContractWrite({
    addressOrName: config.contracts.fancyBearStakingContract.address,
    contractInterface: config.contracts.fancyBearStakingContract.interface,
    functionName: 'unstakeFancyBears',
    args: [[tokenId]],
  });

  const {
    write,
    data: writeData,
    error: writeError,
    isLoading,
  } = useContractWrite(writeConfig);

  const {
    data: waitData,
    error: waitError,
    isLoading: isWaitLoading,
  } = useWaitForTransaction({
    hash: writeData?.hash,
    confirmations: 2,
  });

  useSnackbarRpcError(writeError);
  useSnackbarRpcError(waitError);
  useSnackbarRpcSuccess(waitData);

  useEffect(() => {
    if (!isLoading && waitData && onSuccess) {
      onSuccess();
    }
  }, [isLoading, waitData, tokenId, onSuccess]);

  return [write as () => void, isLoading || isWaitLoading];
};

export default useUnstakeFancyBear;
