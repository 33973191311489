import BoldSpinner from './BoldSpinner';
import { Center } from '@chakra-ui/react';

const Loading = () => (
  <Center h="100vh" w="100vw">
    <BoldSpinner size="xl" />
  </Center>
);

export default Loading;
