import { AspectRatio, Box, BoxProps, Image } from '@chakra-ui/react';
import config from '../constants/config';

export type EmptyFancyBearCardProps = BoxProps;

const EmptyFancyBearCard = ({ children, ...rest }: EmptyFancyBearCardProps) => (
  <Box
    bg="dark.700"
    p="1.5"
    w="full"
    userSelect="none"
    borderRadius="lg"
    overflow="hidden"
    {...rest}
  >
    <AspectRatio
      ratio={1}
      w="full"
      bg="dark.800"
      borderRadius="md"
      overflow="hidden"
    >
      <Image
        src={`${config.urls.publicUrl}/fancy-bear-placeholder.png`}
        w="full"
        position="absolute"
        opacity={0.3}
      />
    </AspectRatio>

    <Box>{children}</Box>
  </Box>
);

export default EmptyFancyBearCard;
