import React from 'react';
import { useAccount, useNetwork } from 'wagmi';
import UnsupportedNetworkPopup from '../components/UnsupportedNetworkPopup';

const NetworkErrorsContainer = () => {
  const { isConnected } = useAccount();
  const { chain } = useNetwork();
  const isWrongNetworkErrorOpen = isConnected && !!chain?.unsupported;

  return isWrongNetworkErrorOpen ? <UnsupportedNetworkPopup isOpen /> : null;
};

export default NetworkErrorsContainer;
