import { Button, ModalProps } from '@chakra-ui/react';
import { useCallback } from 'react';
import useRequestUnstakeFancyBear from '../hooks/useRequestUnstakeFancyBear';
import useTranslate from '../hooks/useTranslate';
import FancyBearImage from './FancyBearImage';
import SimplePopup from './SimplePopup';

type RequestUnstakePopupProps = Omit<ModalProps, 'children'> & {
  tokenId: number;
  onSuccess: () => void;
};

const RequestUnstakePopup = ({
  tokenId,
  onSuccess,
  ...rest
}: RequestUnstakePopupProps) => {
  const translate = useTranslate();
  const [requestUnstake, isLoading] = useRequestUnstakeFancyBear(
    tokenId,
    onSuccess
  );

  const handleRequestUnstake = useCallback(() => {
    requestUnstake();
  }, [requestUnstake]);

  return (
    <SimplePopup
      size="md"
      title={translate('requestUnstakePopup:title')}
      description={translate('requestUnstakePopup:description')}
      isClosable={!isLoading}
      {...rest}
    >
      <FancyBearImage tokenId={tokenId} mt="4" />

      <Button
        w="full"
        mt="6"
        onClick={handleRequestUnstake}
        isLoading={isLoading}
      >
        {translate('requestUnstakePopup:confirm', { tokenId })}
      </Button>
    </SimplePopup>
  );
};

export default RequestUnstakePopup;
