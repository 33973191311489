import useStaking from './useStaking';
import useWalletAssets from './useWalletAssets';
import { useMemo } from 'react';

const useAllOwnedFancyBears = () => {
  const { fancyBears } = useWalletAssets();
  const { stakedTokens } = useStaking();

  return useMemo(
    () =>
      fancyBears && stakedTokens
        ? [
            ...(fancyBears || []),
            ...(stakedTokens?.map(({ nftId }) => nftId) || []),
          ]
        : undefined,
    [fancyBears, stakedTokens]
  );
};

export default useAllOwnedFancyBears;
