import LevelData from '../types/levelData';
import React from 'react';

export type LevelsContextValue = {
  levelsDataByTokenIds?: Record<number, LevelData>;
  readLevelDataByTokenIds: () => void;
};

// @ts-ignore
const LevelsContext = React.createContext<LevelsContextValue>();

LevelsContext.displayName = 'LevelsContext';

export default LevelsContext;
