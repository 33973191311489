import Moment from 'moment';
import Panel from '../components/Panel';
import TinyHeading from '../components/TinyHeading';
import usePeriodDetails from '../hooks/usePeriodDetails';
import useStaking from '../hooks/useStaking';
import useTranslate from '../hooks/useTranslate';
import { FaCalendar } from 'react-icons/fa';
import { HStack, Icon, SimpleGrid, StackProps, Text } from '@chakra-ui/react';

type SummaryBoxProps = StackProps & {
  title: string;
  children: JSX.Element | JSX.Element[];
};

const SummaryBox = ({ title, children, ...rest }: SummaryBoxProps) => (
  <HStack
    bg="dark.600"
    p="3"
    spacing="1"
    align="center"
    justify="space-between"
    overflow="hidden"
    borderRadius="md"
    {...rest}
  >
    <TinyHeading noOfLines={1}>{title}</TinyHeading>
    {children}
  </HStack>
);

const StakingPeriodContainer = () => {
  const translate = useTranslate();
  const { stakingReward } = useStaking();

  const periodDetails = usePeriodDetails(stakingReward);

  if (!stakingReward) {
    return null;
  }

  return (
    <Panel overflow="hidden" bg="dark.700" w="full">
      <TinyHeading mb="2" opacity="1">
        {translate('stakingPeriod:title')}
      </TinyHeading>

      <SimpleGrid columns={2} gap="3">
        <SummaryBox title={translate('common:startedOn')} bg="dark.800">
          <HStack spacing="1.5">
            <Icon as={FaCalendar} color="primary.500" w="2.5" />
            <Text fontSize="sm" fontWeight="semibold">
              {Moment(periodDetails?.beginningOfCurrentPeriod).format(
                'MMM Do YY'
              )}
            </Text>
          </HStack>
        </SummaryBox>

        <SummaryBox title={translate('common:endsOn')} bg="dark.800">
          <HStack spacing="1.5">
            <Icon as={FaCalendar} color="primary.500" w="2.5" />
            <Text fontWeight="semibold" fontSize="sm">
              {Moment(periodDetails?.beginningOfNextPeriod).format('MMM Do YY')}
            </Text>
          </HStack>
        </SummaryBox>
      </SimpleGrid>
    </Panel>
  );
};

export default StakingPeriodContainer;
