import EthIcon from '../components/EthIcon';
import HoneyIcon from '../components/HoneyIcon';
import Currency from '../types/currency';

const currencySymbols = {
  [Currency.Eth]: 'ETH',
  [Currency.Honey]: '$HONEY',
};

const currencyIcons = {
  [Currency.Eth]: EthIcon,
  [Currency.Honey]: HoneyIcon,
};

export const getCurrencySymbol = (currency: Currency) =>
  currencySymbols[currency];

export const getCurrencyIcon = (currency: Currency) => currencyIcons[currency];
