import { useMemo } from 'react';
import StakingReward from '../types/stakingReward';

const usePeriodDetails = (stakingReward?: StakingReward) =>
  useMemo(() => {
    if (!stakingReward) {
      return;
    }

    let [year, month]: (string | number)[] = stakingReward?.periodId.split('-');
    year = parseInt(year!, 10);
    month = parseInt(month!, 10);

    const beginningOfCurrentPeriod = new Date(year, month - 1, 1, 0, 0, 0, 0);
    const beginningOfNextPeriod = new Date(year, month, 1, 0, 0, 0, 0);
    const endOfCurrentPeriod = new Date(year, month, 1, 0, 0, 0, 0);

    const totalSecsInPeriod =
      (endOfCurrentPeriod.getTime() - beginningOfCurrentPeriod.getTime()) /
      1000;

    return {
      beginningOfCurrentPeriod,
      beginningOfNextPeriod,
      endOfCurrentPeriod,
      totalSecsInPeriod,
    };
  }, [stakingReward]);

export default usePeriodDetails;
