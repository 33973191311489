import React from 'react';

const CoinbaseWalletIcon: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#coinbaseWalletIconClipPath)">
      <path
        d="M15 27C21.6274 27 27 21.6274 27 15C27 8.37258 21.6274 3 15 3C8.37258 3 3 8.37258 3 15C3 21.6274 8.37258 27 15 27Z"
        fill="#0052FF"
      />
      <path
        d="M15.1335 10.4092C17.0055 10.4092 18.4906 11.5458 19.0546 13.2361H22.826C22.1428 9.62232 19.1139 7.17391 15.1636 7.17391C10.6776 7.17391 7.17383 10.526 7.17383 15.015C7.17383 19.504 10.59 22.8261 15.1636 22.8261C19.0254 22.8261 22.1145 20.3777 22.7984 16.734H19.0543C18.5195 18.4245 17.0356 19.5908 15.1623 19.5908C12.5776 19.5908 10.7661 17.6376 10.7661 15.015C10.7673 12.3624 12.5496 10.4092 15.1335 10.4092V10.4092Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="coinbaseWalletIconClipPath">
        <rect width="24" height="24" fill="white" transform="translate(3 3)" />
      </clipPath>
    </defs>
  </svg>
);

export default CoinbaseWalletIcon;
