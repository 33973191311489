import stakingRewardsApi from '../api/stakingRewardsApi';
import StakingReward from '../types/stakingReward';
import useFetchPromise from './useFetchPromise';

const useFetchCurrentStakingReward = () =>
  useFetchPromise<StakingReward>(
    stakingRewardsApi.fetchCurrentStakingReward,
    true
  );

export default useFetchCurrentStakingReward;
