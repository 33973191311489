const translations = {
  'common:copy': 'Copy',
  'common:copied': 'Copied to clipboard!',
  'common:disconnect': 'Disconnect',
  'common:free': 'Free',
  'common:claim': 'Claim',
  'common:buy': 'Buy',
  'common:refresh': 'Refresh',
  'common:cancel': 'Cancel',
  'common:stake': 'Stake',
  'common:noResults': 'No results…',
  'common:fancyBear': 'Fancy Bear',
  'common:fancyBears': 'Fancy Bears',
  'common:requestUnstake': 'Request to Unstake',
  'common:unstake': 'Unstake',
  'common:pool': 'Staking Pool',
  'common:level': 'Level',
  'common:minimumReward': 'Minimum Reward',
  'common:cooldown': 'Cooldown',
  'common:startedOn': 'Started on',
  'common:endsOn': 'Ends on',
  'common:startsAtDate': 'Starts on {date}',
  'common:waitMessage': 'Please wait a moment for the metadata to be updated…',
  'common:tokenId': 'Token ID',
  'common:honey': '$HONEY',

  'error:default': 'Oops! Something went wrong.',

  'success:default': 'Success! Thank you.',

  'pool:pool_0': 'LVL 0-1',
  'pool:pool_1': 'LVL 2-4',
  'pool:pool_2': 'LVL 5-9',
  'pool:pool_3': 'LVL 10-14',
  'pool:pool_4': 'LVL 15+',

  'connect:title': 'Connect Your Wallet',
  'connect:appTitle': 'Staking App',
  'connect:appDescription':
    'Stake your Bears to earn rewards including $HONEY token and free NFTs',
  'connect:button:label': 'Connect with {connector}',

  'footer:learnMore': 'About',
  'footer:powered': 'Powered by',
  'footer:contract': 'Contract',

  'walletPopup:title': 'Your Wallet',

  'fancyBearsInWallet:upgrade': 'Upgrade to LVL{level}',
  'fancyBearsInWallet:buy': 'Buy on OpenSea',

  'stakedFancyBears:empty': 'You are not staking any Fancy Bears…',
  'stakedFancyBears:cooldownHint':
    'You will be able to claim your NFT after the cooldown period.',

  'stakingPeriod:title': 'Current Staking Period',
  'stakingPeriod:pools': 'Staking Pools',

  'howItWorks:title': 'Curious How the Fancy Bears Staking Works?',
  'howItWorks:learnMore': 'Learn More',
  'howItWorks:popupTitle': 'Staking in Details',

  'tabs:bearsInWallet': 'Bears in Wallet',
  'tabs:stakedBears': 'Staked Bears',

  'stakePopup:title': 'Stake Your Bear',
  'stakePopup:description': 'Collect $Honey tokens with us and run the hood!',
  'stakePopup:confirm': 'Stake #{tokenId}',

  'requestUnstakePopup:title': 'Are You Sure?',
  'requestUnstakePopup:description':
    'Cooldown period lasts 14 days. After that, you’ll be able to claim the token back to your wallet.',
  'requestUnstakePopup:confirm': 'Request Unstake #{tokenId}',

  'unstakePopup:title': 'Are You Sure?',
  'unstakePopup:description':
    'Remember, if you choose to unstake now, you’ll lose all the tokens collected this month.',
  'unstakePopup:confirm': 'Yes, Unstake #{tokenId}',

  'claiming:title': 'Collected $HONEY',
  'claiming:claimableToWallet': 'Claimable to Wallet',
  'claiming:claimableToHive': 'Claimable to Hive',

  'claimToHive:title': 'Claim Collected $HONEY',
  'claimToHive:description':
    'Claim $HONEY tokens to Hive, so you can use it later to buy traits in Trait Swap app and level up your Bears.',
  'claimToHive:claimAll': 'Claim All',

  'unsupportedNetwork:title': 'Wrong Network',
  'unsupportedNetwork:description':
    'Your wallet is connected to an unsupported network. Please change it to: {chainName}',
  'unsupportedNetwork:switch': 'Switch Network',
};

export default translations;
