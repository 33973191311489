import { AmountsPerTokenId } from '../hooks/useClaimedHoneyForBearsToHive';
import { BigNumber } from 'ethers';
import React from 'react';
import Whitelist from '../types/whitelist';

export type ClaimingV2ContextValue = {
  status?: number;
  readStatus: () => void;
  claimedHoneyByWallet?: BigNumber;
  readClaimedHoneyByWallet: () => void;
  claimedHoneyPerFancyBear?: AmountsPerTokenId;
  readClaimedHoneyPerFancyBear: () => void;

  claimableHoneyToWallet: BigNumber;
  claimableHoneyPerFancyBear: Whitelist<number>;
  claimableHoneyForFancyBearsSum: BigNumber;
};

// @ts-ignore
const ClaimingV2Context = React.createContext<ClaimingV2ContextValue>();

ClaimingV2Context.displayName = 'ClaimingV2Context';

export default ClaimingV2Context;
