import Countdown from 'react-countdown';
import FancyBearImage from './FancyBearImage';
import HoneyIcon from './HoneyIcon';
import Moment from 'moment';
import PoolBadge from './PoolBadge';
import StakedToken from '../types/stakedToken';
import StakingReward from '../types/stakingReward';
import TinyHeading from './TinyHeading';
import usePeriodDetails from '../hooks/usePeriodDetails';
import usePoolRewardDetails from '../hooks/usePoolRewardDetails';
import useTranslate from '../hooks/useTranslate';
import WalletAsset from './WalletAsset';
import { BigNumber } from 'ethers';
import { Box, Divider, HStack, Text, useInterval } from '@chakra-ui/react';
import { FaClock } from 'react-icons/fa';
import { formatEtherBalance, inRange } from '../utils/numberUtils';
import { useEffect, useMemo, useState } from 'react';

type StakedFancyBearTileProps = {
  stakedToken: StakedToken;
  stakingReward: StakingReward;
  children?: JSX.Element | JSX.Element[];
};

const StakedFancyBearTile = ({
  stakedToken,
  stakingReward,
  children,
}: StakedFancyBearTileProps) => {
  const translate = useTranslate();

  const now = new Date();
  const stakedAt = new Date(stakedToken.createdAt);

  const periodDetails = usePeriodDetails(stakingReward);

  const [currentReward, setCurrentReward] = useState<BigNumber>(
    BigNumber.from('0')
  );

  const isFirstPeriod = useMemo(
    () =>
      now.getFullYear() === stakedAt.getFullYear() &&
      now.getMonth() === stakedAt.getMonth(),
    [now.getMonth(), stakedAt.getMonth()]
  );

  const rewardsPool = useMemo(
    () =>
      stakingReward.pools.find(pool =>
        inRange(
          stakedToken.currentPeriod.nftLevel,
          pool.nftLevelRange.from,
          pool.nftLevelRange.to
        )
      ),
    [stakingReward, stakedToken]
  );

  const rewardsData = usePoolRewardDetails(rewardsPool?.poolId, stakingReward);

  const isEarning =
    !isFirstPeriod && rewardsData && !rewardsData.perTokenPerSec.isZero();

  useEffect(() => {
    if (rewardsData) {
      setCurrentReward(rewardsData.collectedTillNow);
    }
  }, [rewardsData]);

  useInterval(
    () => {
      if (rewardsData) {
        setCurrentReward(currentReward?.add(rewardsData.perTokenPerSec));
      }
    },
    isEarning ? 1000 : null
  );

  return (
    <Box w="full" bg="dark.700" borderRadius="lg">
      <HStack spacing="0">
        <Box w="50%" p="1.5">
          <FancyBearImage tokenId={stakedToken.nftId} size="256" />
        </Box>
        <Box p="3" pl="1.5" w="50%">
          <Text fontSize="lg" fontWeight="semibold" w="full">
            {translate('common:fancyBear')} #{stakedToken.nftId}
          </Text>

          <Divider my="2" />

          {rewardsPool && (
            <HStack justify="space-between" fontSize="sm">
              <TinyHeading opacity="1">{translate(`common:pool`)}</TinyHeading>
              <PoolBadge pool={rewardsPool} />
            </HStack>
          )}

          {isFirstPeriod ? (
            <Box mt="2" mb="3">
              <WalletAsset
                icon={FaClock}
                iconSize="1.25rem"
                title={translate('common:startsAtDate', {
                  date: Moment(periodDetails?.beginningOfNextPeriod).format(
                    'MMM Do YY'
                  ),
                })}
                value={
                  <Countdown date={periodDetails?.beginningOfNextPeriod} />
                }
              />
            </Box>
          ) : (
            <Box mt="2" mb="3">
              <WalletAsset
                icon={HoneyIcon}
                title={translate('common:minimumReward')}
                value={`~${formatEtherBalance(currentReward)}`}
                hint={`+ ${formatEtherBalance(currentReward.mul(2))} in hive`}
              />
            </Box>
          )}

          {children}
        </Box>
      </HStack>
    </Box>
  );
};

export default StakedFancyBearTile;
