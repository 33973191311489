import { Badge, BadgeProps } from '@chakra-ui/react';

type LevelBadgeProps = BadgeProps & {
  level: number;
  size?: 'sm' | 'md';
};

const LevelBadge = ({ level, size = 'md', ...rest }: LevelBadgeProps) => (
  <Badge
    borderRadius="full"
    fontWeight="semibold"
    bg="primary.500"
    color="dark.900"
    px="1.5"
    fontSize={size === 'sm' ? '0.625rem' : undefined}
    {...rest}
  >
    {level === undefined ? `lvl–` : `lvl${level}`}
  </Badge>
);

export default LevelBadge;
