const HiWIllustration: React.FC<React.SVGProps<SVGSVGElement>> = props => (
  <svg
    width="173"
    height="73"
    viewBox="0 0 173 73"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#hiwIllustrationClipPath)">
      <path
        d="M0 53.7508L32.2599 6.33339L42.3963 31.9634L71.7431 0L76.7695 12.821L101.627 1.11487L91.3474 49.7301L126.891 11.5519L131.774 34.9167L173 6.33339L159.031 50.0148L146.34 33.3867L112.743 62.6934L111.811 51.782L80.4349 73L64.4004 35.1064L0 53.7508Z"
        fill="#D9A049"
      />
      <path
        d="M96.3976 27.3379C82.9898 3.03624 60.0784 16.2604 60.0784 16.2604"
        stroke="#18151D"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M88.7207 24.7287L96.5529 27.6345L98.2721 19.9134"
        stroke="#18151D"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M87.6819 46.1009C82.3211 39.4947 76.6261 37.3124 71.6355 37.1227"
        stroke="#18151D"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M81.1033 45.1639L87.849 46.3855L88.0997 39.9217"
        stroke="#18151D"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M141.886 38.7357L115.429 23.4359L97.2454 54.4624L123.703 69.7622L141.886 38.7357Z"
        fill="#f2af4a"
        stroke="#18151D"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M135.594 39.1864L118.115 29.0814C117.291 28.607 116.229 28.8798 115.739 29.6982L106.462 45.5198C105.985 46.3381 106.259 47.3937 107.083 47.88L124.562 57.9849C125.386 58.4593 126.449 58.1865 126.938 57.3682L136.215 41.5466C136.705 40.7163 136.43 39.6608 135.594 39.1864Z"
        stroke="#18151D"
        strokeMiterlimit="10"
      />
      <path
        d="M102.236 51.0822L120.885 61.8751"
        stroke="#18151D"
        strokeMiterlimit="10"
      />
      <path
        d="M124.3 45.3774C124.121 45.84 123.834 46.2432 123.476 46.5872C123.118 46.9311 122.688 47.1802 122.21 47.3462C121.745 47.5004 121.243 47.5597 120.754 47.5123C120.264 47.4648 119.787 47.2988 119.357 47.0497C117.721 46.1009 116.611 43.2426 118.378 41.9735C120.348 40.5028 121.673 39.3643 123.309 40.3131C124.921 41.2382 124.921 43.1477 124.3 45.3774Z"
        stroke="#18151D"
        strokeMiterlimit="10"
      />
      <path
        d="M122.7 43.8356C122.473 44.0016 122.199 44.1202 121.912 44.1558C121.769 44.2033 121.613 44.2151 121.458 44.1914C121.303 44.1677 121.16 44.1202 121.04 44.0372C120.909 43.9542 120.802 43.8593 120.718 43.7288C120.634 43.6102 120.575 43.4679 120.539 43.3137C120.42 43.0647 120.384 42.78 120.408 42.4954C120.431 41.9142 120.67 41.4873 122.079 42.2345C123.488 43.0054 123.178 43.5035 122.7 43.8356Z"
        fill="#18151D"
      />
      <path
        d="M117.566 49.9081C117.566 49.9081 121.554 51.2601 124.145 50.2283C125.064 49.9199 125.876 49.3862 126.52 48.6746C127.165 47.9748 127.595 47.109 127.774 46.1839C128.037 44.8793 128.168 43.4442 128.168 43.4442C130.902 43.3493 130.902 41.9498 130.759 41.0484C130.687 40.6096 130.186 38.9136 127.428 40.2301L121.279 36.6601C121.028 33.6595 119.297 34.039 118.903 34.1813C118.079 34.5253 116.838 35.2013 118.091 37.6089C118.091 37.6089 116.897 38.4985 115.859 39.2694C115.154 39.898 114.629 40.7045 114.33 41.594C114.02 42.4954 113.96 43.4679 114.139 44.4049C114.438 46.8718 117.566 49.9081 117.566 49.9081Z"
        stroke="#18151D"
        strokeMiterlimit="10"
      />
      <path
        d="M43.0172 13.0582L12.4646 30.7774L33.5255 66.5955L64.0661 48.8762L43.0172 13.0582Z"
        fill="#f2af4a"
        stroke="#18151D"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M39.8891 19.5932L19.7118 31.2993C18.7566 31.8567 18.4343 33.0664 18.9954 34.0271L29.7288 52.2919C30.29 53.2408 31.5197 53.561 32.4748 53.0036L52.6522 41.2975C53.6074 40.74 53.9297 39.5303 53.3686 38.5696L42.6352 20.3048C42.074 19.356 40.8443 19.0357 39.8891 19.5932Z"
        stroke="#18151D"
        strokeMiterlimit="10"
      />
      <path
        d="M32.9524 59.6928L54.4908 47.2039"
        stroke="#18151D"
        strokeMiterlimit="10"
      />
      <path
        d="M39.722 34.4067C40.0801 34.8574 40.3309 35.3673 40.4741 35.9248C40.6174 36.4822 40.6294 37.0515 40.5219 37.6089C40.4144 38.1664 40.1876 38.7001 39.8533 39.1626C39.519 39.6252 39.0892 40.0166 38.5877 40.2894C36.6894 41.3805 33.1792 40.8587 32.8927 38.3799C32.5226 35.569 32.1286 33.6002 34.015 32.509C35.8895 31.4297 37.8236 32.509 39.722 34.4067Z"
        stroke="#18151D"
        strokeMiterlimit="10"
      />
      <path
        d="M37.2625 35.1301C37.2983 35.4622 37.2625 35.7943 37.1431 36.0908C37.1073 36.2569 37.0237 36.4229 36.9162 36.5534C36.8088 36.6957 36.6774 36.8024 36.5222 36.8973C36.367 36.9803 36.1999 37.0396 36.0208 37.0515C35.8417 37.0634 35.6745 37.0515 35.5074 36.9922C35.185 36.9566 34.8746 36.8499 34.6119 36.6601C34.0389 36.3043 33.7404 35.818 35.2925 34.8455C36.8685 33.8611 37.1908 34.466 37.2625 35.1301Z"
        fill="#18151D"
      />
      <path
        d="M40.486 43.7051C40.486 43.7051 44.1275 40.491 44.5454 37.3243C44.7603 36.2331 44.6887 35.1183 44.3305 34.0627C43.9843 33.019 43.3634 32.0939 42.5157 31.3942C41.3457 30.386 39.9607 29.4372 39.9607 29.4372C41.4293 26.65 39.9965 25.8554 39.0175 25.4877C38.5399 25.3098 36.5341 24.8354 36.2954 28.3461L29.1915 32.4616C26.0037 31.0028 25.4067 32.9478 25.3232 33.4223C25.1918 34.4422 25.1799 36.0671 28.3319 36.1857C28.3319 36.1857 28.5587 37.8817 28.7497 39.3643C28.9885 40.4317 29.5019 41.4042 30.2302 42.2226C30.9704 43.0409 31.9137 43.6577 32.9762 44.0135C35.6268 45.1046 40.486 43.7051 40.486 43.7051Z"
        stroke="#18151D"
        strokeMiterlimit="10"
      />
      <path
        d="M133.899 23.3055C138.238 23.3055 141.755 19.8115 141.755 15.5014C141.755 11.1913 138.238 7.69733 133.899 7.69733C129.56 7.69733 126.043 11.1913 126.043 15.5014C126.043 19.8115 129.56 23.3055 133.899 23.3055Z"
        fill="#f2af4a"
        stroke="#18151D"
        strokeMiterlimit="10"
      />
      <path
        d="M135.153 19.5813C134.58 19.2848 134.006 19.0002 133.433 18.7155C133.254 18.6325 133.183 18.502 133.183 18.3241C133.183 17.7904 133.183 17.2567 133.183 16.723C133.183 16.557 133.254 16.4383 133.421 16.3553C133.959 16.0825 134.496 15.8098 135.033 15.537C135.176 15.4658 135.32 15.4539 135.475 15.537C136.012 15.8098 136.561 16.0825 137.099 16.3553C137.17 16.3909 137.23 16.4383 137.278 16.5095C137.314 16.5688 137.337 16.6518 137.337 16.723C137.337 17.2686 137.337 17.8141 137.337 18.3479C137.337 18.5139 137.266 18.6325 137.099 18.7155C136.526 19.0002 135.953 19.2967 135.367 19.5932L135.153 19.5813Z"
        fill="#18151D"
      />
      <path
        d="M134.687 13.5919C134.687 13.8765 134.687 14.173 134.687 14.4577C134.687 14.6474 134.615 14.7779 134.448 14.8728C133.911 15.1693 133.374 15.4658 132.836 15.7623C132.765 15.7979 132.693 15.8216 132.621 15.8216C132.55 15.8216 132.466 15.7979 132.406 15.7623C131.869 15.4658 131.344 15.1693 130.807 14.8846C130.639 14.7898 130.556 14.6593 130.556 14.4695C130.556 13.8884 130.556 13.3191 130.556 12.7379C130.556 12.5482 130.628 12.4058 130.807 12.3228C131.344 12.0382 131.881 11.7417 132.406 11.4452C132.466 11.4096 132.55 11.3859 132.621 11.3859C132.693 11.3859 132.777 11.4096 132.836 11.4452C133.374 11.7417 133.923 12.0382 134.46 12.3347C134.615 12.4177 134.699 12.5482 134.687 12.7261C134.687 12.9989 134.687 13.2954 134.687 13.5919Z"
        fill="#18151D"
      />
      <path
        d="M110.104 18.1699C113.164 18.1699 115.644 15.7061 115.644 12.6668C115.644 9.62746 113.164 7.1636 110.104 7.1636C107.044 7.1636 104.564 9.62746 104.564 12.6668C104.564 15.7061 107.044 18.1699 110.104 18.1699Z"
        fill="#f2af4a"
        stroke="#18151D"
        strokeMiterlimit="10"
      />
      <path
        d="M111.071 15.5844C110.665 15.3828 110.259 15.1693 109.853 14.9677C109.734 14.9084 109.674 14.8135 109.674 14.683C109.674 14.3035 109.674 13.924 109.674 13.5563C109.674 13.4377 109.722 13.3547 109.841 13.2954C110.224 13.1056 110.606 12.9158 110.976 12.7142C111.083 12.6549 111.179 12.6549 111.286 12.7142C111.668 12.904 112.05 13.1056 112.432 13.2954C112.48 13.3191 112.528 13.3547 112.552 13.4021C112.576 13.4496 112.599 13.497 112.588 13.5563C112.588 13.9358 112.588 14.3272 112.588 14.7067C112.588 14.8253 112.54 14.9084 112.42 14.9677C112.014 15.1693 111.608 15.3828 111.203 15.5844H111.071Z"
        fill="#18151D"
      />
      <path
        d="M110.737 11.3503C110.737 11.5519 110.737 11.7535 110.737 11.9552C110.737 12.0856 110.689 12.1805 110.57 12.2517C110.188 12.4533 109.806 12.6668 109.436 12.8803C109.388 12.904 109.34 12.9277 109.28 12.9277C109.221 12.9277 109.173 12.9158 109.125 12.8803C108.743 12.6668 108.373 12.4652 107.991 12.2635C107.871 12.1924 107.812 12.1093 107.812 11.967C107.812 11.5638 107.812 11.1487 107.812 10.7454C107.812 10.6031 107.86 10.5201 107.991 10.4489C108.373 10.2473 108.743 10.0338 109.125 9.83217C109.173 9.80845 109.221 9.78473 109.28 9.78473C109.34 9.78473 109.388 9.79659 109.436 9.83217C109.818 10.0457 110.2 10.2473 110.582 10.4608C110.689 10.5201 110.749 10.6149 110.749 10.7336C110.737 10.9352 110.737 11.1487 110.737 11.3503Z"
        fill="#18151D"
      />
      <path
        d="M149.42 54.3082C157.293 54.3082 163.676 47.968 163.676 40.147C163.676 32.326 157.293 25.9859 149.42 25.9859C141.547 25.9859 135.165 32.326 135.165 40.147C135.165 47.968 141.547 54.3082 149.42 54.3082Z"
        fill="#f2af4a"
        stroke="#18151D"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M151.856 47.3581C150.817 46.8363 149.778 46.3025 148.728 45.7807C148.405 45.6265 148.262 45.4012 148.262 45.0572C148.274 44.0847 148.274 43.1121 148.262 42.1396C148.262 41.8312 148.393 41.6177 148.692 41.4635C149.671 40.9773 150.65 40.4791 151.617 39.981C151.891 39.8387 152.142 39.8387 152.405 39.981C153.384 40.4791 154.363 40.9773 155.354 41.4754C155.485 41.5347 155.593 41.6296 155.676 41.7482C155.748 41.8668 155.784 41.9973 155.784 42.1396C155.784 43.124 155.784 44.1084 155.784 45.0928C155.784 45.4012 155.64 45.6146 155.354 45.7688C154.303 46.2907 153.265 46.8244 152.214 47.3581H151.856Z"
        fill="#18151D"
      />
      <path
        d="M151.008 36.4704C151.008 36.9922 151.008 37.5141 151.008 38.0359C151.008 38.3799 150.877 38.6171 150.566 38.7831C149.587 39.3168 148.62 39.8505 147.641 40.3961C147.522 40.4673 147.379 40.5029 147.247 40.5029C147.104 40.5029 146.973 40.4673 146.853 40.3842C145.886 39.8505 144.919 39.3168 143.94 38.7831C143.63 38.6171 143.486 38.368 143.486 38.0241C143.498 36.9804 143.498 35.9248 143.486 34.8811C143.486 34.5253 143.618 34.2881 143.94 34.122C144.919 33.6002 145.886 33.0546 146.853 32.5209C146.973 32.4497 147.104 32.4023 147.247 32.4023C147.39 32.4023 147.522 32.4379 147.641 32.509C148.62 33.0546 149.611 33.5883 150.59 34.122C150.877 34.2762 151.008 34.5134 151.008 34.8336C150.996 35.3911 151.008 35.9366 151.008 36.4704Z"
        fill="#18151D"
      />
    </g>
    <defs>
      <clipPath id="hiwIllustrationClipPath">
        <rect width="173" height="73" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default HiWIllustration;
