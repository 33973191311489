import { Box, BoxProps, Icon } from '@chakra-ui/react';
import { FaCheck, FaCheckDouble } from 'react-icons/fa';
import SelectionIndicator from './SelectionIndicator';

export type SelectableCardProps = BoxProps & {
  isSelected?: boolean;
  isDisabled?: boolean;
  isActive?: boolean;
};

const SelectableCard: React.FC<SelectableCardProps> = ({
  children,
  isSelected = false,
  isDisabled = false,
  isActive = false,
  bg = 'dark.700',
  onClick,
  ...rest
}) => {
  return (
    <Box
      bg={bg}
      p="1.5"
      w="full"
      outline="2px solid"
      userSelect="none"
      borderRadius="lg"
      outlineColor={
        isActive ? 'primary.500' : isSelected ? 'bright.50' : 'transparent'
      }
      cursor={isDisabled ? 'not-allowed' : onClick ? 'pointer' : undefined}
      transition="all 0.15s"
      tabIndex={onClick ? 0 : undefined}
      role={onClick ? 'button' : undefined}
      overflow="hidden"
      position="relative"
      onClick={event => {
        if (!isDisabled) {
          onClick?.(event);
        }
      }}
      {...rest}
    >
      <SelectionIndicator
        position="absolute"
        zIndex="10"
        top="0"
        left="0"
        w="8"
        h="8"
        bg={bg}
        color={isActive ? 'primary.500' : 'bright.50'}
        icon={
          isActive && isSelected ? (
            <Icon as={FaCheckDouble} w={3.5} h={3.5} />
          ) : (
            <Icon as={FaCheck} w={3.5} h={3.5} />
          )
        }
        isSelected={isActive || isSelected}
      />

      {children}
    </Box>
  );
};

export default SelectableCard;
