import { Button, ButtonProps } from '@chakra-ui/react';

export type TabProps = Omit<ButtonProps, 'color' | 'colorScheme'> & {
  isActive?: boolean;
};

const Tab = ({ isActive = false, ...rest }: TabProps) => (
  <Button
    variant="solid"
    colorScheme="dark"
    color={isActive ? 'primary.500' : 'bright.50'}
    bg={isActive ? 'dark.800' : 'transparent'}
    borderBottom="2px solid"
    borderBottomRadius="none"
    borderColor={isActive ? 'primary.500' : 'transparent'}
    {...rest}
  />
);

export default Tab;
