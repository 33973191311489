/* eslint-disable react-hooks/exhaustive-deps */
import { Button, ButtonProps, useInterval } from '@chakra-ui/react';
import useTranslate from '../hooks/useTranslate';

import { useState } from 'react';

type RefreshCountdownProps = ButtonProps & {
  seconds?: number;
};

const RefreshCountdown = ({ seconds = 30, ...rest }: RefreshCountdownProps) => {
  const translate = useTranslate();
  const [count, setCount] = useState<number>(seconds);
  const [isCounting, setIsCounting] = useState<boolean>(true);

  useInterval(
    () => {
      if (count > 1) {
        setCount(count - 1);
      } else {
        setIsCounting(false);
      }
    },
    isCounting ? 1000 : null
  );

  return (
    <Button
      onClick={() => {
        window.location.reload();
      }}
      isDisabled={isCounting}
      w="full"
      colorScheme="primary"
      {...rest}
    >
      {isCounting ? count : translate('common:refresh')}
    </Button>
  );
};

export default RefreshCountdown;
