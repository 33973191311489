import config from '../constants/config';
import { BigNumber } from 'ethers';
import { useContractRead } from 'wagmi';

const useHoneyBalance = (
  walletAddress?: string
): [honeyBalance: BigNumber | undefined, readHoneyBalance: () => void] => {
  const { data, refetch } = useContractRead({
    addressOrName: config.contracts.honeyTokenContract.address,
    contractInterface: config.contracts.honeyTokenContract.interface,
    functionName: 'balanceOf',
    args: walletAddress,
  });

  return [data as BigNumber | undefined, refetch];
};

export default useHoneyBalance;
