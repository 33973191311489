import FancyBearImage from './FancyBearImage';
import HoneyIcon from './HoneyIcon';
import TinyHeading from './TinyHeading';
import useTranslate from '../hooks/useTranslate';
import { BigNumber } from 'ethers';
import { Box, HStack, Icon, StackProps, Text } from '@chakra-ui/react';
import { formatEtherBalance } from '../utils/numberUtils';

type FancyBearTileProps = StackProps & {
  tokenId: number;
  claimableHoney: BigNumber;
};

const FancyBearTile = ({
  tokenId,
  claimableHoney,
  children,
  ...rest
}: FancyBearTileProps) => {
  const translate = useTranslate();

  return (
    <HStack
      borderRadius="lg"
      p="1.5"
      bg="dark.800"
      justifyContent="space-between"
      {...rest}
    >
      <HStack spacing="4">
        <FancyBearImage size="256" width="60px" tokenId={tokenId} />

        <Box>
          <TinyHeading noOfLines={1}>{translate('common:tokenId')}</TinyHeading>
          <Text fontSize="lg" fontWeight="semibold">
            #{tokenId}
          </Text>
        </Box>

        <Box>
          <TinyHeading>{translate('common:honey')}</TinyHeading>
          <HStack align="center" spacing="1">
            <Icon as={HoneyIcon} w="4" h="4" color="primary.500" />
            <Text fontSize="lg" fontWeight="semibold" noOfLines={1} maxW="full">
              {formatEtherBalance(claimableHoney)}
            </Text>
          </HStack>
        </Box>
      </HStack>

      {children}
    </HStack>
  );
};

export default FancyBearTile;
