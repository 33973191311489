import { Badge, BadgeProps } from '@chakra-ui/react';
import useTranslate from '../hooks/useTranslate';
import { StakingPool } from '../types/stakingReward';

type PoolBadgeProps = BadgeProps & {
  pool: StakingPool;
};

const PoolBadge = ({ pool, size = 'md', ...rest }: PoolBadgeProps) => {
  const translate = useTranslate();

  return (
    <Badge
      borderRadius="full"
      fontWeight="semibold"
      bg="dark.500"
      color="primary.500"
      px="1.5"
      fontSize={size === 'sm' ? '0.625rem' : undefined}
      {...rest}
    >
      {translate(`pool:${pool.poolId}`)}
    </Badge>
  );
};

export default PoolBadge;
