import { useContractRead } from 'wagmi';
import config from '../constants/config';
import ClaimingStatus from '../types/claimingStatus';

const useClaimingStatusV2 = (): [
  status: ClaimingStatus,
  readStatus: () => void
] => {
  const { data, refetch } = useContractRead({
    addressOrName: config.contracts.fancyBearStakingRewardContract.address,
    contractInterface:
      config.contracts.fancyBearStakingRewardContract.interface,
    functionName: 'claimingStatus',
  });

  return [data as any as ClaimingStatus, refetch];
};

export default useClaimingStatusV2;
