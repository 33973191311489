import { BigNumber } from 'ethers';
import { useState } from 'react';
import { useContractRead } from 'wagmi';
import config from '../constants/config';

export type AmountsPerTokenId = Record<number, BigNumber>;

const useClaimedHoneyForBearsToHive = (
  tokenIds?: number[]
): [amountsPerBear: AmountsPerTokenId | undefined, read: () => void] => {
  const [amountsPerBear, setAmountsPerBear] = useState<AmountsPerTokenId>();

  const { refetch } = useContractRead({
    addressOrName: config.contracts.fancyBearStakingRewardContract.address,
    contractInterface:
      config.contracts.fancyBearStakingRewardContract.interface,
    functionName: 'getClaimedHoneyForBearsToHive',
    args: [tokenIds],
    onSettled: (data, error) => {
      if (error || !data || !tokenIds) {
        setAmountsPerBear({});
      } else {
        setAmountsPerBear(
          tokenIds.reduce(
            (prev, tokenId, index) => ({
              ...prev,
              [tokenId]: data[index] as any as BigNumber,
            }),
            {}
          )
        );
      }
    },
  });

  return [tokenIds?.length ? amountsPerBear : {}, refetch];
};

export default useClaimedHoneyForBearsToHive;
