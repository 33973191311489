import config from '../constants/config';
import TokenStakingData from '../types/tokenStakingData';
import { BigNumber } from 'ethers';
import { useContractReads } from 'wagmi';
import { useMemo } from 'react';

const fancyBearStakingContract = {
  addressOrName: config.contracts.fancyBearStakingContract.address,
  contractInterface: config.contracts.fancyBearStakingContract.interface,
};

const useStakingDataByTokenIds = (
  tokenIds?: number[]
): [
  stakingDataByTokenIds: Record<number, TokenStakingData> | undefined,
  readStakingDataByTokenIds: () => void
] => {
  const contracts = useMemo(
    () =>
      tokenIds?.map(tokenId => ({
        ...fancyBearStakingContract,
        functionName: 'stakingDataByTokenId',
        args: tokenId,
      })) || [],
    [tokenIds]
  );

  const { data, refetch } = useContractReads({
    contracts,
    allowFailure: false,
  });

  const result: Record<number, TokenStakingData> | undefined = useMemo(() => {
    if (!tokenIds || !data) {
      return;
    }

    return (data as any as { timestamp: BigNumber; owner: string }[])?.reduce(
      (aggr, current, index) => ({
        ...aggr,
        [tokenIds[index]]: {
          owner: current.owner,
          timestamp: current.timestamp.toNumber(),
        },
      }),
      {}
    );
  }, [tokenIds, data]);

  return [result, refetch];
};

export default useStakingDataByTokenIds;
